import type { UserSession } from '@wision/api';

export const sessionState = () => {
  const state = useState<UserSession>('session', () => undefined);

  const get = computed(() => state.value);

  const set = (newValue?: UserSession) => {
    state.value = newValue;
  };

  return { get, set };
};

